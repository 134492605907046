import React from 'react';
import { Table, Row, Col } from 'react-bootstrap';

const TeamInfoTable = ({ data }) => {
  return (
    <Col md={6}>
      <h2 className="pt-2 pb-2">Team {data.teamName} Info</h2>
      <Table striped bordered hover>
        <thead className="bg-primary text-white">
          <tr>
            <th className="col-6">Data</th>
            <th className="col-6">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="col-6">Player Name in Frame</td>
            <td className="col-6">{data.playerInFrameName}</td>
          </tr>
          <tr>
            <td className="col-6">Team Name</td>
            <td className="col-6">{data.teamName}</td>
          </tr>
          <tr>
            <td className="col-6">Team Towers</td>
            <td className="col-6">{data.teamTowers}</td>
          </tr>
          <tr>
            <td className="col-6">Team Gold</td>
            <td className="col-6">{data.teamGold}</td>
          </tr>
          <tr>
            <td className="col-6">Team Kills</td>
            <td className="col-6">{data.teamKills}</td>
          </tr>
        </tbody>
      </Table>
    </Col>
  );
};

export default TeamInfoTable;

import React from "react";
import FileUploader from "./components/fileuploader/FileUploader";
import { Container, Row, Col } from "react-bootstrap";

const Home = () => {
  return (
    <Container className="pt-4">
      <Row>
        <Col>
          <h1>Overview</h1>
        </Col>
      </Row>
      <Row className="pt-3">
        <Col>
          <p>
            This site provides a rough around the edges demo of how Google Cloud
            Vision AI might be used to extract information from e-sports games.
          </p>
          <p>
            When you upload an image using the form below, the image will be
            processed through Google Cloud's{" "}
            <a href="https://cloud.google.com/vision?hl=en">Vision AI API</a>.
            This API can detect a range of different features from images and
            documents, including text, landmarks, logos and more (see{" "}
            <a href="https://cloud.google.com/vision/docs/features-list">
              here
            </a>{" "}
            for the full list).
          </p>
          <p>
            For this particular demo, Document Text Detection is used to extract
            text from a League of Legends stream. Amongst other properties, the
            Vision AI API returns the detected text, bounding polygons, and a
            confidence score.
          </p>
          <p>
            A custom microservice then compares the detected text and its
            bounding box to a known set of bounding boxes that contain
            information we are interested in from the stream.
          </p>
          <p>
            The extracted information is then shown in a structured format below
            the image.
          </p>
          <p>
            To get started, upload a frame from a League of Legends stream
            below.
          </p>
        </Col>
      </Row>
      <Row className="pt-4">
        <Col>
          <h3>Notes and Caveats</h3>
        </Col>
      </Row>
      <Row className="pt-3">
        <Col>
          <p>
            <ol>
              <li>
                This is an unrefined rough around the edges demo put together in
                a few hours
              </li>
              <li>
                This demo is only using the Text Detection capability from
                Vision AI
              </li>
              <li>
                It could be extended using AutoML or a custom model to also
                detect non-text custom image objects (for example dragons types)
              </li>
              <li>
                This demo is looking for text in specific known areas (for
                example we know the score is in a fixed place, so we can search
                for detected text in this area and be relatively confident it is
                our score)
              </li>
              <li>
                Consequently it will not perform well with non-standard frames
                from the stream (for example replays, etc)
              </li>
              <li>
                Champion levels in a League of Legends stream are in a very
                noisy area, which the model currently struggles with. These are
                not returned for now
              </li>
            </ol>
          </p>
        </Col>
      </Row>
      <Row className="pt-4">
        <Col>
          <h3>Try it out</h3>
        </Col>
      </Row>
      <Row className="pt-3">
        <Col>
          <p>
            To try detection on your own frame, upload one below. Your frame
            should meet the following requirements:
          </p>
          <p>
            <ol>
              <li>Frame should be 1920x1080px.</li>
              <li>
                Avoid screenshots, extract the frame from a 1080p video stream
                with ffmpeg if neccesary (e.g.{" "}
                <code>
                  ffmpeg -i input_video.mp4 -vf "fps=1" frame_%03d.png
                </code>
                )
              </li>
              <li>
                Make sure the frame is from a game play section of the stream
                (avoid replay's, etc)
              </li>
              <li>
                <a href="https://www.youtube.com/watch?v=jFvoex6PWZM">Here</a> is an example of an e-sports stream.
              </li>
              <li>
                If you don't have any frames to hand, save and try one of these
                example frames:
                <ol>
                  <li>
                    <a href="/examples/frame1.jpg">Example One</a>
                  </li>
                  <li>
                    <a href="/examples/frame2.jpg">Example Two</a>
                  </li>
                  <li>
                    <a href="/examples/frame3.jpg">Example Three</a>
                  </li>
                  <li>
                    <a href="/examples/frame4.jpg">Example Four</a>
                  </li>
                  <li>
                    <a href="/examples/frame5.jpg">Example Five</a>
                  </li>
                </ol>
              </li>
            </ol>
          </p>
        </Col>
      </Row>

      <Row>
        <Col className="pt-4">
          <FileUploader />
        </Col>
      </Row>
    </Container>
  );
};

export default Home;

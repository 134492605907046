import React from 'react';
import { Table, Col } from 'react-bootstrap';

const CharacterInfoTable = ({ team, data }) => {
  return (
    <Col md={6}>
      <h2 className="pt-2 pb-2">Team {team} Characters</h2>
      <Table striped bordered hover>
        <thead className="bg-primary text-white">
          <tr>
            <th className="col-3">Name</th>
            <th className="col-1">Level</th>
            <th className="col-1">Kills</th>
            <th className="col-2">Deaths</th>
            <th className="col-2">Assists</th>
            <th className="col-3">CreepScore</th>
          </tr>
        </thead>
        <tbody>
          {data.map((character, index) => (
            <tr key={index}>
              <td className="col-2">{character.name}</td>
              <td className="col-2">{character.level}</td>
              <td className="col-2">{character.kills}</td>
              <td className="col-2">{character.deaths}</td>
              <td className="col-2">{character.assists}</td>
              <td className="col-2">{character.creepScore}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Col>
  );
};

export default CharacterInfoTable;

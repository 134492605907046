import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthWrapper from "./AuthWrapper";
import Home from "./Home";
import Login from "./Login";
import Results from "./Results";
import Header from "./components/header/Header";
import { Container } from "react-bootstrap";

function App() {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <Container>
        <BrowserRouter>
          <AuthWrapper>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Home />} />
              <Route path="/results" element={<Results />} />
            </Routes>
          </AuthWrapper>
        </BrowserRouter>
      </Container>
    </div>
  );
}

export default App;

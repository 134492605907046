import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFirestore, collection, addDoc, doc } from 'firebase/firestore';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';

const FileUploader = () => {
  const [file, setFile] = useState(null);
  const [docId, setDocId] = useState(null);
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    const db = getFirestore();
    const newDocRef = doc(collection(db, 'images'));
    const uniqueId = newDocRef.id;
    const fileExtension = file.name.split('.').pop();
    const fileNameWithExtension = `${uniqueId}.${fileExtension}`;

    const storage = getStorage();
    const storageRef = ref(storage, `images/${fileNameWithExtension}`);
    await uploadBytes(storageRef, file);

    const downloadURL = await getDownloadURL(storageRef);
    const gsUrl = `gs://${storageRef.bucket}/${storageRef.fullPath}`;
    const gsBucket = storageRef.bucket;
    const gsObject = storageRef.fullPath;

    const docRef = await addDoc(collection(db, 'images'), {
      name: fileNameWithExtension,
      url: downloadURL,
      gsURL: gsUrl,
      bucket: gsBucket,
      object: gsObject,
      processed: false,
    });

    setDocId(docRef.id);
    navigate(`/results?resultID=${docRef.id}`);
  };

  return (
    <Container>
      <Row>
        <Col>
          <Form.Group controlId="formFile">
            <Form.Control type="file" onChange={handleFileChange} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <Button onClick={handleUpload} className="w-100 mt-4">Upload</Button>
        </Col>
      </Row>
      {docId && (
        <Row>
          <Col>
            <p>Document ID: {docId}</p>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default FileUploader;

import React, { useEffect, useState } from "react";
import {
  Navbar,
  Nav,
  Image,
  Button,
  NavItem,
} from "react-bootstrap";
import { auth } from "../../firebase";  // Update the path based on your folder structure

const Header = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <Navbar expand="lg" bg="primary" variant="dark" className="px-3">
      <Navbar.Brand href="/">League of Legends - Vision AI Demo</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">{/* Left-aligned Nav Items */}</Nav>
        <Nav>
          {user && (
            <>
              <NavItem className="d-flex align-items-center">
                <Image
                  src={user.photoURL}
                  roundedCircle
                  className="mr-3"
                  alt="User"
                  width={40}
                  height={40}
                />
                <Button variant="primary" onClick={handleLogout}>
                  Logout
                </Button>
              </NavItem>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TeamInfoTable from "./components/teamInfo/TeamInfo";
import CharacterInfoTable from "./components/characterInfo/characterInfo";
import {
  Container,
  Row,
  Col,
  Alert,
  Image,
  Spinner,
  Table,
} from "react-bootstrap";
import { doc, onSnapshot, getFirestore } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const Results = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const resultID = queryParams.get("resultID");
  const [data, setData] = useState(null);
  const [originalImageUrl, setOriginalImageUrl] = useState(null);
  const [processedImageUrl, setProcessedImageUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (resultID) {
      const db = getFirestore();
      const docRef = doc(db, "images", resultID);
      const storage = getStorage();

      const unsubscribe = onSnapshot(
        docRef,
        async (docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data();
            setData(data);
            const originalImageRef = ref(storage, `gs://${data.bucket}/${data.object}`);
            const originalUrl = await getDownloadURL(originalImageRef);
            setOriginalImageUrl(originalUrl);

            if (!data.processedObject) {
              setProcessedImageUrl("");
            } else {
              const processedImageRef = ref(storage, `gs://${data.bucket}/${data.processedObject}`);
              const processedUrl = await getDownloadURL(processedImageRef);
              setProcessedImageUrl(processedUrl);
            }


          } else {
            setError("Document not found.");
          }
        },
        (e) => {
          setError("Error fetching document.");
        }
      );

      return () => unsubscribe();
    }
  }, [resultID]);

  return (
    <Container className="pt-4">
      {resultID && data && (
        <Row>
          <Col className="pb-4">
            <h1>Results</h1>
          </Col>
        </Row>
      )}
      {resultID && data && !data.processed ? (
        <>
          <Row>
            <Col>
              <Row>
                <Image src={originalImageUrl} alt="Uploaded" fluid />
                <Row className="justify-content-center">
                  <Col xs="auto" className="text-center pt-4 pb-3">
                    <Spinner animation="border" role="status" className="mb-2">
                      <span className="visually-hidden">Processing...</span>
                    </Spinner>
                    <div>
                      This image is processing. The page will update
                      automatically once complete.
                    </div>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </>
      ) : resultID && data && data.processed ? (
        <Row>
          <Col>
            <Row>
              <Row>
                <Col className="text-center">
                  <span className="fw-bold">Uploaded Image</span>
                  <Image className="pt-2" src={originalImageUrl} alt="Uploaded" fluid />
                </Col>
              </Row>
            </Row>
            <Row className="pt-4">
              <Col>
                <h2 className="pt-2 pb-2">Game Information</h2>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th className="col-6">Data</th>
                      <th className="col-6">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="col-6">Game Duration</td>
                      <td className="col-6">{data.gameInfo.gameDuration}</td>
                    </tr>
                    <tr>
                      <td className="col-6">Game Score</td>
                      <td className="col-6">{data.gameInfo.gameScore}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Row>
                <TeamInfoTable data={data.gameInfo.team1Info}/>
                <TeamInfoTable data={data.gameInfo.team2Info}/>
              </Row>
              <Row className="mb-10">
                <CharacterInfoTable data={data.gameInfo.team1Info.champions} team={data.gameInfo.team1Info.teamName}/>
                <CharacterInfoTable data={data.gameInfo.team2Info.champions}  team={data.gameInfo.team1Info.teamName}/>
              </Row>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <Alert variant="danger" className="pt-3 mt-2">
              <Alert.Heading>Error</Alert.Heading>
              {error
                ? error
                : "Result ID not found. Please provide a valid Result ID."}
            </Alert>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Results;

import React from "react";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "./firebase";
import { useNavigate } from "react-router-dom";
import GoogleButton from "react-google-button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";

const Login = () => {
  const navigate = useNavigate();

  const handleGoogleLogin = () => {
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        navigate("/");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Container>
      <Row className="justify-content-center pt-5">
        <Col xs={12} className="text-center">
          You must sign in!
        </Col>
        <Col xs={12} className="text-center">
          <Row className="d-flex justify-content-center">
            <GoogleButton onClick={handleGoogleLogin} />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { auth } from './firebase';

const AuthWrapper = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user && location.pathname !== '/login') {
        window.location.href = '/login';
      }
    });

    return () => unsubscribe();
  }, [location.pathname]);

  return <>{children}</>;
};

export default AuthWrapper;
